import React from 'react';
import type { NextPage } from 'next'
import Head from 'next/head'

const Custom404: NextPage = () => {
  return (
    <>
      <Head>
        <title>Página Não Encontrada - Plataforma de Serviços Formadores Credenciados</title>
      </Head>
      <main className="py-3 px-5">
        <h1>Página não encontrada.</h1>
      </main>
    </>
  )
}

export default Custom404
